@media screen and (max-width: 767px) {

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    margin: 0 0 1rem;
  }

  h1, .h1 {
    font-size: 1.5rem;
  }

  h2, .h2 {
    font-size: 1.25rem;
  }

  h3, .h3 {
    font-size: 1.25rem;
  }

  h4, .h4 {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    line-height: 1.14;
  }

  .main-section {
    padding: 3.5rem 0;
  }

  .section-title--center,
  .section-title--faded {
    margin-bottom: 2.5rem;
  }

  .section-title--vertical:after {
    margin: 0 1rem;
  }

  .kicker {
    font-size: 1rem;
  }

  .cta {
    font-size: 0.875rem;
    padding: 1rem 3.75rem 0.875rem 1.125rem;
  }

  .cta:after {
    width: 2.75rem;
  }

  /* CEO QUOTE SECTION */
  .ceo-quote__image {
    right: -7%;
  }

  .ceo-quote__content {
    padding: 7rem 1.375rem 0 1.1875rem;
  }

  .ceo-quote__text {
    max-width: 100%;
  }


  /* HOW CAN WE HELP */
  .how-can-we-help__title {
    margin-bottom: 1.5rem;
  }

  /* RECTANGLES */

  .rectangles {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .rectangles__item-title {
    font-size: 1rem;
  }

  .rectangles__item-text {
    font-size: 0.875rem;
    line-height: 1.25;
  }

  .rectangles__item-cta {
    font-size: 1rem;
  }


  /* HOW WE SECTION */
  .how-we-recruit__container::before {
    top: calc(-3.5rem - 75px);
    left: -25px;
  }

  .how-we-recruit__container::after {
    bottom: calc(-3.5rem - 50px);
  }

  .how-we-recruit.light .how-we-recruit__container::before {
    display: none;
  }

  .how-we-recruit__items {
    margin-top: 2.5rem;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0;
  }

  .how-we-recruit__item {
    column-gap: 1.5rem;
  }

  .how-we-recruit__item--text {
    font-size: 0.875rem;
    line-height: 1.5;
  }

  .how-we-recruit__item--title {
    font-size: 1rem;
    line-height: 1;
  }

  .how-we-recruit__item--icon {
    width: 3rem;
    height: auto;
  }

  /* G-CORE SECTION */
  .g-core {
    padding-bottom: 0;
  }

  .g-core__wrapper {
    flex-direction: column;
    padding: 0;
  }

  .g-core__column {
    width: 100%;
  }

  .g-core__column.left {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 7rem;
    padding-top: 0;
  }

  .g-core__text {
    font-size: .75rem;
    line-height: 1.125rem;
    text-align: center;
  }

  .accordion {
    width: 100%;
  }

  .accordion__content p {
    font-size: .875rem;
    line-height: 1.3125rem;
  }

  /* NAVIGATION MENU */
  .menu {
    height: 5rem;
  }

  .menu__logo {
    width: 7rem;
    height: auto;
  }

  .menu__getquote {
    width: 6.25rem;
  }

  .menu__getquote > .left {
    padding: .313rem .5rem;
    font-size: 0.625rem;
    white-space: nowrap;
  }

  .menu__getquote > .right {
    padding: .5rem;
  }

  .menu__getquote > .right > .arrow--right {
    width: .5rem;
    height: .5rem;
  }

  .menu__items {
    font-size: 0.875rem;
    line-height: 1.42;
  }

  .menu__item-link,
  .menu__item-link:hover {
    padding-top: 0;
  }

  .menu__btn:checked ~ .menu__items {
    max-height: calc(100vh - 5rem);
    height: calc(100vh - 5rem);
  }

  .menu__button--mobile:before {
    width: 1.5rem;
    background-position-x: calc(100% - 0.5rem);
    background-size: 40%;
  }

  .menu__button--mobile {
    font-size: .625rem;
    padding: .5rem 2rem .5rem .5rem;
  }

  /* PAGE HEADER */
  .header {
    margin-top: -5rem;
  }

  .header__background {
    height: 85vh;
    object-fit: cover;
    object-position: 88%;
  }

  .header__title {
    font-size: 1.25rem;
  }

  .header__text {
    font-size: 0.875rem;
    line-height: 1.57;
    width: 20rem;
    max-width: 80%;
  }


  /* ================================================ */

  /* CASE STUDIES */
  #case-studies-swiper,
  #case-studies-swiper-for-abroad {
    max-height: 35rem;
    width: 100%;
  }

  #case-studies {
    padding-bottom: 4rem;
  }

  #case-studies > .section-center {
    padding: 0 1.25rem;
    margin: 0;
    flex-direction: column;
    width: 100%;
  }

  .section-title--vertical {
    writing-mode: unset;
    transform: unset;
    font-size: 1.5rem;
  }

  .section-title--vertical:after {
    width: 2.5rem;
    height: .5rem;
    background: linear-gradient(90deg, #262626 0%, #262626 50%, #478ffb 50%, #478ffb 100%);
    margin: 1rem 0 2rem;
  }

  .studies {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .studies__item-content {
    padding: 0 1.5rem 1.5rem;
  }

  .studies__item-title {
    line-height: 1.125;
    margin-bottom: 0.5rem;
  }

  .studies__item-subtitle {
    font-size: 0.875rem;
    line-height: 1.42;
  }

  .studies__item-logo {
    padding: 1rem 1rem 0;
  }

  .learn-more {
    padding: 1.5rem 2rem;
    flex-direction: row;
  }

  .learn-more__icon {
    transform: none;
    margin-top: 0;
    margin-right: 0.5rem;
  }

  .learn-more__text {
    writing-mode: unset;
    transform: unset;
  }

  .studies .swiper-pagination,
  .studies .swiper-button-next,
  .studies .swiper-button-prev {
    display: none;
  }

  /* REVIEWS */

  .reviews {
    margin: 0;
    padding: 3.125rem 0;
    height: 29.5625rem;
    overflow: hidden;
  }

  .reviews .swiper-pagination-bullets {
    display: flex;
    right: 50%;
    bottom: 2rem;
    top: unset;
    transform: translateX(50%);
  }

  .reviews .swiper-button-next,
  .reviews .swiper-button-prev {
    bottom: 0.875rem;
    top: unset;
    transform: rotate(90deg);
  }

  .reviews .swiper-pagination {
    text-align: center;
    padding-right: 0;
  }

  .reviews__block {
    padding: 0;
    margin-right: 0;
  }

  .reviews .swiper-slide-active {
    padding: 0 1.25rem;
  }

  .reviews .swiper-slide-next {
    padding: 0 1.25rem;
  }

  .reviews__item-wrapper {
    height: 15.625rem;
    width: 100%;
  }

  .reviews__item {
    padding: 2.5rem 1.5rem 2rem;
  }

  .reviews__item-quote {
    margin: 0 0 1rem;
    font-size: 0.875rem;
    line-height: 1.42;
  }

  .reviews__item-author {
    margin-bottom: 0;
    font-size: 0.875rem;
    line-height: 1.42;
  }

  .reviews__item-note {
    font-size: 0.625rem;
  }

  .reviews__widget {
    margin-top: 0;
    align-items: flex-start;
    padding-left: 1.25rem;
    flex-direction: column;
    padding-bottom: 2rem;
  }

  .reviews__widget-text {
    font-size: .75rem;
    margin-bottom: .75rem;
  }

  .testimonials {
    gap: 1.25rem;
  }

  .testimonials__quote {
    font-size: 1.375rem;
    line-height: 1.3;
  }

  .testimonials--case-studies {
    margin-bottom: 49px;
  }

  .testimonials--case-studies,
  .contact--case-studies {
    padding: 0 17px;
  }

  .testimonials__quotation {
    margin-top: 0;
  }

  .testimonials__quote {
    margin-bottom: 3rem;
  }

  .testimonials__image {
    width: 54px;
    height: 54px;
  }

  .author__name {
    font-size: 0.875rem;
  }

  .author__title {
    font-size: 12px;
  }

  /* CUSTOMERS */
  .customers__title {
    font-size: 0.875rem;
  }

  .customers__text {
    font-size: 1.5rem;
    max-width: 100%;
  }

  .customers__item {
    width: 20%;
  }

  /* WE MAKE IT BETTER */
  .it-better {
    padding: 1.5rem 1.5rem 0;
  }

  .it-better__container {
    position: relative;
  }

  .it-better__background {
    position: absolute;
    top: -1.5rem;
    bottom: 1.5rem;
    right: -1.5rem;
    left: 4.5rem;
  }

  .it-better__corner.rt {
    right: 0;
    top: 0;
  }

  .it-better__corner.lb {
    bottom: 0;
    left: 0;
  }

  .it-better__white-block {
    width: 6rem;
    height: 6rem;
    right: -1.5rem;
    top: -1.5rem;
  }

  .it-better__content {
    position: static;
    padding: 1.5rem 0 0;
    width: 100%;
  }

  .it-better__text {
    line-height: 1.5;
  }

  .it-better__text--small {
    line-height: 1.5;
    margin-bottom: 2rem;
  }

  /* TECH STACK */

  #tech-stack {
    padding: 2.5rem 0;
  }

  .tech-stack {
    flex-direction: column;
    align-items: stretch;
    gap: 1.5rem;
    text-align: center;
  }

  .tech-stack__items {
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 1.5rem;
  }

  .tech-stack__item {
    min-width: 4rem;
    flex-shrink: 0;
    object-fit: contain;
  }


  /* EXPERTS */
  .experts {
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: unset;
    padding-bottom: 0;
  }

  .experts__content {
    padding: 1.5rem 0 0;
    max-width: 100%;
  }

  .experts__image {
    align-self: flex-end;
    margin-right: 1.5rem;
    max-width: unset;
  }

  .experts__text {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  /* SERVICES */

  .services {
    flex-direction: column;
    gap: 4rem;
  }

  .services__item:nth-child(n) {
    position: static;
    top: unset;
    bottom: unset;
  }

  .services__item:nth-child(2n) {
    text-align: right;
    align-self: flex-end;
    align-items: flex-end;
  }

    /* TECHNOLOGY */
  .technology {
    grid-template-rows: 3rem;
  }

  /* CONTACT */

  .contact {
    height: fit-content;
    flex-direction: column;
    background: linear-gradient(240.98deg, rgba(234, 240, 246, 0.109375) -6.98%, #EAF0F6 32.21%, rgba(234, 240, 246, 0) 81.54%);
 margin-top: 10rem;
  }

  .contact:before {
    display: none;
  }

  .contact__image {
    width: 100%;
    position: relative;
    top: -10rem;
  }

  .contact__content {
    margin-top: -10rem;
    padding: 3.4375rem 1.25rem;
    width: 100%;
  }

  /* ================================================ */
  /* FOOTER */
  footer {
    padding: 3rem 0;
  }

  .footer {
    flex-direction: column-reverse;
    justify-content: flex-start;
    flex-wrap: unset;
    font-size: 0.75rem;
  }

  .footer__details {
    padding-top: 3rem;
    border-top: 1px solid rgba(233, 243, 255, 10%);
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2.5rem;
    row-gap: 2rem;
  }

  .footer__details-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
  }

  .footer__details-logo {
    display: none;
  }

  .footer__socials {
    font-size: 1.125rem;
    margin-top: 0;
  }

  a.footer__socials-item,
  a.footer__socials-item:link,
  a.footer__socials-item:hover {
    width: 1.125rem;
  }

  .footer__sections {
    justify-content: flex-start;
    margin: 3rem 0;
  }

  .footer__section:last-child {
    text-align: left;
  }

  .footer__section-item {
    margin-bottom: 4rem;
  }

  .footer-menu__item {
    margin-bottom: 0.5rem;
  }

  .footer-menu__item:last-child {
    margin-bottom: 0;
  }

  .footer__section-title {
    font-size: 1.25rem;
    line-height: 1;
    margin-bottom: 1rem;
  }

  .footer__header-mobile {
    display: flex;
    padding-bottom: 3rem;
    border-bottom: 1px solid rgba(233, 243, 255, 10%);
  }

  /* ==================== BREADCRUMBS ===================*/

  .breadcrumbs {
    font-size: 0.75rem;
    padding-bottom: 1rem;
    column-gap: 0.5rem;
  }

  /*=========================== WHATS IN ==================================*/

  /* BOXES */

  .boxes {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.75rem;
  }

  .boxes .box {
    padding: 1.25rem;
    height: 16.5rem;
  }

  .boxes .box__image {
    height: 2.5rem;
  }

  .boxes .box__text {
    line-height: 1.5;
    font-size: 0.75rem;
  }

  /*=========================== KEY BENEFITS ==================================*/

  #key-benefits .boxes {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-gap: 1rem;
  }

  #key-benefits .boxes .box {
    padding: 1.25rem;
  }

  #key-benefits .boxes .box__header {
    height: unset;
    margin-top: 2rem;
  }

  /*===================== PICK A PROJECT =========================*/

  .pick-project__container {
    flex-direction: column-reverse;
    align-items: stretch;
  }

  .pick-project__content {
    width: 100%;
  }

  .pick-project__list li:not(:last-child) {
    margin-bottom: 1rem;
  }

  .pick-project__list,
  .pick-project__list-item-text {
    font-size: 0.875rem;
  }

  .pick-project__side {
    max-width: unset;
    margin-bottom: 3rem;
  }

  .pick-project__side::after {
    display: block;
    content: '';
    padding-bottom: 100%;
  }

  .pick-project__side--photo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-width: unset;
  }

  .join-us {
    margin: 3.5rem 0;
  }

  .join-us__container {
    padding: 3rem 1.25rem;
    background-size: cover;
  }

  .join-us__container .kicker {
    margin: 0;
  }

  .join-us__container h3 {
    max-width: 50%;
  }

  .join-us__cta {
    margin-top: 2rem;
    padding: 0.75rem 3.375rem 0.75rem 0.75rem;
    font-size: 0.75rem;
  }

}


